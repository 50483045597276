.App {
  text-align: left;
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-Shell-Header {
  background-color: #2A53C0;
  color: white;
  text-align: left;
  font-size: 2em;
}

.App-Shell-Navbar {
  background-color: #EDF2FF;
  text-align: left;
  font-size: 1em;
}

.App-Shell-Sidebar {
  background-color: #EDF2FF;
}

.App-Shell-Footer {
  background-color: #EDF2FF;;
}

.search-table {
  margin-left: -15px;
}

ion-icon {
  pointer-events: none;
}


.search-header {
    background-color: #F8F9FA;
    }

.label-header {
    background-color: #2A53C0;
    color: white;
    }

thead.tr.th {
  color: white;
}


.HoverCard.Dropdown {
  margin: 0;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

.content-block {
  display: flex;
  padding: 10px;
  margin: 10px;
  border: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
